<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="items.data"
            :show_loading="items.isLoading"
            :total_registros="total"
            @paginar="paginate"
        >
            <template v-slot:header.estado="{ header }">
                <span @click.stop="isStatusesModalOpen = true" style="cursor: pointer;">
                    {{ header.text }}
                    <v-icon
                        color="#FFFFFF !important"
                        style="font-size: 18px !important; margin-left: 2px;"
                    >
                        mdi mdi-help-circle-outline
                    </v-icon>
                </span>
            </template>
            <!-- <template v-slot:item.tiempo_restante="{ item:{tiempo_restante} }">
                <p v-if="tiempo_restante !== null">
                    {{ Number(tiempo_restante?.split(' ')[0]) > 0 ? `${tiempo_restante.split(' ')[0]} dias` : '' }}
                    {{ Number(tiempo_restante?.split(' ')[2]) > 0 ? `${tiempo_restante.split(' ')[2]} horas` : '' }}
                    {{ Number(tiempo_restante?.split(' ')[4]) > 0 ? `${tiempo_restante.split(' ')[4]} minutos` : 'El tiempo se ha acabado' }}
                </p>
                <p v-else>
                    No tiene tiempo límite
                </p>
            </template> -->
            <template v-slot:item.acciones="{ item }">
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" style="font-size: 26px !important;" @click.stop="redirectToDetailsPage(item)">
                            mdi-eye-circle
                        </v-icon>
                    </template>
                    <span>Ver detalles</span>
                </v-tooltip>
            </template>
          <template v-slot:item.estado="{ item }">
            {{ item.proSolCom_bitProSol[0].descripcion}}
          </template>
            <template v-slot:item.CMSolicitudCompra.created_at="{ item }">
                {{ formatDate(item.CMSolicitudCompra.created_at) }}
            </template>
            <template v-slot:item.precio_de_oferta="{ item }">
                ${{ item.precio_de_oferta }}
            </template>
            <template v-slot:item.subtotal="{ item }">
                ${{ item.subtotal }}
            </template>
        </v-data-table>
        <CMEstadosModal 
            :isOpen="isStatusesModalOpen" 
            :statuses="statuses" 
            @on-visibility-change="handleStatusesModal" 
        />
    </div>
</template>
<script>
import DataTableComponent from "@/components/DataTableComponent.vue";
import CMEstadosModal from '@/components/CMEstadosModal.vue';
import { PAGINATION_PROPERTY_NAMES } from '@/utils/loadable.js';
import { formatDate } from '@/utils/datetime';

export default {
    components: { DataTableComponent, CMEstadosModal },
    name: 'ItemList',
    emits: ['on-paginate'],
    props: {
        items: { type: Object, required: true },
        statuses: { type: Array, required: true },
    },
    data: () => ({
        isStatusesModalOpen: false,
        headers: [
            { sortable: false, align: 'center', value: 'CMOfertaProductoConvenio.CMProducto.nombre', 'text': 'Nombre' },
            { sortable: false, align: 'center', value: 'CMSolicitudCompra.Institucion.nombre', 'text': 'Institución' },
            // { sortable: false, align: 'center', value: 'tiempo_restante', 'text': 'Tiempo restante' },
            { sortable: false, align: 'center', value: 'estado', 'text': 'Estado' },
            { sortable: false, align: 'center', value: 'CMSolicitudCompra.created_at', 'text': 'Fecha de creación' },
            { sortable: false, align: 'center', value: 'precio_de_oferta', 'text': 'Precio' },
            { sortable: false, align: 'center', value: 'cantidad', 'text': 'Cantidad' },
            { sortable: false, align: 'center', value: 'subtotal', 'text': 'Sub total' },
            { sortable: false, align: 'center', value: 'acciones', 'text': 'Acciones' },
        ],
    }),
    computed: {
        total() {
            return this.items.pagination.total_rows;
        },
    },
    methods: {
        redirectToDetailsPage(item) {
            this.$router.push(`/cm-solicitudes-proveedor/${item.id}`);
        },
        formatDate(date) {
            return formatDate(date)
        },
        handleStatusesModal(visible) {
            this.isStatusesModalOpen = visible;
        },
        // Fetching
        paginate(pagination) {            
            const { cantidad_por_pagina, pagina } = pagination;
            const filters = {};
            filters[PAGINATION_PROPERTY_NAMES.CURRENT_PAGE] = pagina;
            filters[PAGINATION_PROPERTY_NAMES.PAGE_SIZE] = cantidad_por_pagina;

            this.$emit('on-paginate', filters);
        },
    },
}
</script>